import { Col, Row } from "react-bootstrap";

const ProgressBar = (props) => {
  const { activeStep } = props;
  return (
    <Row className="roi-progress-bar">
      <Col lg={4} sm={4} xs={4} className={activeStep >= 1 ? "active" : ""}>
        <div></div>
      </Col>
      <Col lg={4} sm={4} xs={4} className={activeStep >= 2 ? "active" : ""}>
        <div></div>
      </Col>
      <Col lg={4} sm={4} xs={4} className={activeStep >= 3 ? "active" : ""}>
        <div></div>
      </Col>
    </Row>
  );
};

export default ProgressBar;
