// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import ProgressBar from "./ProgressBar";
import { useState } from "react";
import { toast } from "react-toastify";

const Step1Component = (props) => {
  const [emailValid, setEmailValid] = useState(true);
  const { goto, state, setValue } = props;

  const next = () => {
    if (state.step1.invalid) {
      toast.error("Please fill in the required fields");
    } else {
      //const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!re.test(state.step1.salesRepEmail)) setEmailValid(false);
      else goto(2);
    }
  };

  return (
    <>
      <p>With the ROI Calculator, help your clients understand the impact the Life Fitness Connect System can have on their business. This tool takes the cost of the system, guides you through some assumptions and returns financial insights to help you close the deal.</p>

      <Container id="step1">
        {!emailValid && (
          <Row style={{ marginBottom: "0px" }}>
            <Col lg={12}>
              <Alert variant="danger">Invalid Sales Rep Email</Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col lg={6}>
            <Form.Label htmlFor="customerFirstName">First Name *</Form.Label>
            <Form.Control
              type="text"
              id="customerFirstName"
              placeholder="First name"
              onChange={(e) => {
                setValue(e, "step1", "customerFirstName");
              }}
              value={state.step1.customerFirstName}
            />
          </Col>
          <Col lg={6}>
            <Form.Label htmlFor="customerLastName">Last Name *</Form.Label>
            <Form.Control
              type="text"
              id="customerLastName"
              placeholder="Last name"
              onChange={(e) => {
                setValue(e, "step1", "customerLastName");
              }}
              value={state.step1.customerLastName}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Form.Label htmlFor="salesRepEmail">Sales Rep Email *</Form.Label>
            <Form.Control
              type="email"
              id="salesRepEmail"
              placeholder="Placeholder@gmail.com"
              onChange={(e) => {
                setValue(e, "step1", "salesRepEmail");
              }}
              value={state.step1.salesRepEmail}
            />
          </Col>
          <Col lg={6}>
            <Form.Label htmlFor="customerFacilityName">Facility Name *</Form.Label>
            {/* <OverlayTrigger key="customerFacilityNameTooltip" placement="right" overlay={<Tooltip id={`customer-facility-tooltip`}>This is a test tooltip for Facility Name</Tooltip>}>
              <span className="customer-tooltip">?</span>
            </OverlayTrigger> */}
            <Form.Control
              type="text"
              id="customerFacilityName"
              placeholder="Greatest Gym Ever"
              onChange={(e) => {
                setValue(e, "step1", "customerFacilityName");
              }}
              value={state.step1.customerFacilityName}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Form.Label htmlFor="customerSegment">Segment *</Form.Label>
            {/* <OverlayTrigger key="customerSegmentTooltip" placement="right" overlay={<Tooltip id={`customer-segment-tooltip`}>This is a test for Segment</Tooltip>}>
              <span className="customer-tooltip">?</span>
            </OverlayTrigger> */}
            <Form.Select
              id="customerSegment"
              onChange={(e) => {
                setValue(e, "step1", "customerSegment");
              }}
              value={state.step1.customerSegment}
            >
              <option value="">Select best option</option>
              <option value="Health Club">Health Club</option>
              <option value="Hospitality">Hospitality</option>
              <option value="Athletics">Athletics</option>
              <option value="Multi-Unit Housing">Multi-Unit Housing</option>
              <option value="Distributor/Dealer">Distributor/Dealer</option>
              <option value="Education">Education</option>
              <option value="Amenity">Amenity</option>
              <option value="Uniformed Service">Uniformed Service</option>
              <option value="Other">Other</option>
            </Form.Select>
          </Col>
          <Col lg={3} xs={6} sm={6}>
            <Form.Label htmlFor="customerZipCode">Zip Code</Form.Label>
            <Form.Control
              type="number"
              id="customerZipCode"
              placeholder="00000"
              onChange={(e) => {
                setValue(e, "step1", "customerZipCode");
              }}
              value={state.step1.customerZipCode}
            />
          </Col>
          <Col lg={3} xs={6} sm={6}>
            <Form.Label htmlFor="customerCurrentCustomer">Current Customer *</Form.Label>
            <br />
            <Form.Check
              type="radio"
              name="customerCurrentCustomer"
              id="customerCurrentCustomerYes"
              label="Yes"
              value="Yes"
              checked={state.step1.customerCurrentCustomer === "Yes"}
              onChange={(e) => {
                setValue(e, "step1", "customerCurrentCustomer");
              }}
            />
            <Form.Check
              type="radio"
              name="customerCurrentCustomer"
              id="customerCurrentCustomerNo"
              label="No"
              value="No"
              checked={state.step1.customerCurrentCustomer === "No"}
              onChange={(e) => {
                setValue(e, "step1", "customerCurrentCustomer");
              }}
            />
          </Col>
        </Row>
        <Row className="next-button">
          <Col lg={12}>
            <div></div>
          </Col>
          <Col lg={8} md={8} sm={7} xs={7} style={{ marginBottom: "0px" }}></Col>
          <Col lg={4} md={4} sm={5} xs={12}>
            <Button className={state.step1.invalid ? "disabled" : ""} onClick={next}>
              Next
            </Button>
          </Col>
        </Row>
        <ProgressBar activeStep={1} />
      </Container>
    </>
  );
};

export default Step1Component;
