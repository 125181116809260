import { Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ProgressBar from "./ProgressBar";
import { toast } from "react-toastify";

const Step2Component = (props) => {
  const { goto, state, setValue } = props;

  const calculateResults = () => {
    if (state.step2.invalid) {
      toast.error("Please fill in the required fields!");
    } else goto(3);
  };

  return (
    <>
      <p>
        Please fill out each field below. When completed click <strong>Calculate for Results</strong> button to proceed.
      </p>

      <Container id="step2">
        <Row>
          <Col lg={6}>
            <Form.Label htmlFor="inputSystemCost">System Cost *</Form.Label>
            <OverlayTrigger
              key="inputSystemCostTooltip"
              placement="right"
              delay={{ hide: 1000 }}
              overlay={
                <Tooltip id={`system-cost-tooltip`}>
                  <strong>System Cost</strong>
                  <br />
                  Enter the total quoted amount
                  <br />
                  for the Life Fitness Connect
                  <br />
                  Hardware System.
                </Tooltip>
              }
            >
              <span className="customer-tooltip">?</span>
            </OverlayTrigger>
            <Form.Control
              min={0}
              type="number"
              id="inputSystemCost"
              placeholder="$$.$$"
              onChange={(e) => {
                setValue(e, "step2", "inputSystemCost");
              }}
              value={state.step2.inputSystemCost}
            />
          </Col>
          <Col lg={6}>
            <Form.Label htmlFor="inputDiscountRate">Discount Rate %</Form.Label>
            <OverlayTrigger
              key="inputDiscountRateTooltip"
              placement="right"
              delay={{ hide: 1000 }}
              overlay={
                <Tooltip id={`discount-rate-tooltip`}>
                  <strong>Discount Rate</strong>
                  <br />
                  Use 10% or provided by customer.
                </Tooltip>
              }
            >
              <span className="customer-tooltip">?</span>
            </OverlayTrigger>
            <Form.Control
              min={0}
              type="number"
              id="inputDiscountRate"
              placeholder="10"
              onChange={(e) => {
                setValue(e, "step2", "inputDiscountRate");
              }}
              value={state.step2.inputDiscountRate}
            />
          </Col>
        </Row>
        <Row className="step2-subheader">
          <Col lg={12}>
            <Form.Label>
              <strong>Monthly Memberships Added</strong>
            </Form.Label>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Form.Label htmlFor="inputAvgNetProfitPerMember">Avg Net Profit Per Member</Form.Label>
            <OverlayTrigger
              key="inputAvgNetProfitPerMemberTooltip"
              placement="right"
              delay={{ hide: 1000 }}
              overlay={
                <Tooltip id={`avg-netprofit-permember-tooltip`}>
                  <strong>Average Net Profit Per Member</strong>
                  <br />
                  If the customer cannot provide,
                  <br />
                  take an average or weighted
                  <br />
                  amount of their membership rates
                  <br />
                  and apply the industry standard of
                  <br />
                  20% per EZFacility.
                </Tooltip>
              }
            >
              <span className="customer-tooltip">?</span>
            </OverlayTrigger>
            <Form.Control
              min={0}
              type="number"
              id="inputAvgNetProfitPerMember"
              placeholder="$$.$$"
              onChange={(e) => {
                setValue(e, "step2", "inputAvgNetProfitPerMember");
              }}
              value={state.step2.inputAvgNetProfitPerMember}
            />
          </Col>
          <Col lg={6}>
            <Form.Label htmlFor="inputNumberOfNewMembers">Number of New Members (0-100)</Form.Label>
            <OverlayTrigger
              key="inputNumberOfNewMembersTooltip"
              placement="right"
              delay={{ hide: 1000 }}
              overlay={
                <Tooltip id={`number-newmembers-tooltip`}>
                  <strong>Number of New/Retained Members</strong>
                  <br />
                  Estimate can be increased for return
                  <br />
                  to be positive.
                </Tooltip>
              }
            >
              <span className="customer-tooltip">?</span>
            </OverlayTrigger>
            <Form.Control
              min={0}
              max={100}
              type="number"
              id="inputNumberOfNewMembers"
              placeholder="0-100"
              onChange={(e) => {
                setValue(e, "step2", "inputNumberOfNewMembers");
              }}
              value={state.step2.inputNumberOfNewMembers}
            />
          </Col>
        </Row>
        <Row className="step2-subheader">
          <Col lg={12}>
            <Form.Label>
              <strong>Monthly Personal Training Clients Added</strong>
            </Form.Label>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Form.Label htmlFor="inputAvgNetProfitPerClient">Avg Net Profit Per Client</Form.Label>
            <OverlayTrigger
              key="inputAvgNetProfitPerClientTooltip"
              placement="right"
              delay={{ hide: 1000 }}
              overlay={
                <Tooltip id={`avg-netprofit-perclient-tooltip`}>
                  <strong>Average Net Profit Per Client</strong>
                  <br />
                  If the customer cannot provide,
                  <br />
                  take an average or weighted
                  <br />
                  amount of their personal training
                  <br />
                  rates and apply the industry
                  <br />
                  standard of 50% per NASM Blog.
                </Tooltip>
              }
            >
              <span className="customer-tooltip">?</span>
            </OverlayTrigger>
            <Form.Control
              type="number"
              min={0}
              id="inputAvgNetProfitPerClient"
              placeholder="$$.$$"
              onChange={(e) => {
                setValue(e, "step2", "inputAvgNetProfitPerClient");
              }}
              value={state.step2.inputAvgNetProfitPerClient}
            />
          </Col>
          <Col lg={6}>
            <Form.Label htmlFor="inputNumberOfNewClients">Number of New Clients (0-100)</Form.Label>
            <OverlayTrigger
              key="inputNumberOfNewClientsTooltip"
              placement="right"
              delay={{ hide: 1000 }}
              overlay={
                <Tooltip id={`number-newmembers-tooltip`}>
                  <strong>Number of New/Retained Clients</strong>
                  <br />
                  Estimate can be increased for return
                  <br />
                  to be positive.
                </Tooltip>
              }
            >
              <span className="customer-tooltip">?</span>
            </OverlayTrigger>
            <Form.Control
              min={0}
              max={100}
              type="number"
              id="inputNumberOfNewClients"
              placeholder="0-100"
              onChange={(e) => {
                setValue(e, "step2", "inputNumberOfNewClients");
              }}
              value={state.step2.inputNumberOfNewClients}
            />
          </Col>
        </Row>
        <Row className="next-button">
          <Col lg={12}>
            <div></div>
          </Col>
          <Col lg={4} md={4} sm={4} xs={12} style={{ marginBottom: "0px" }}></Col>
          <Col lg={3} md={3} sm={3} xs={12}>
            <Button
              variant="secondary"
              onClick={() => {
                goto(1);
              }}
            >
              Back
            </Button>
          </Col>
          <Col lg={5} md={5} sm={5} xs={12}>
            <Button className={state.step2.invalid ? "disabled" : ""} onClick={calculateResults}>
              Calculate for Results
            </Button>
          </Col>
        </Row>
        <ProgressBar activeStep={2} />
      </Container>
    </>
  );
};

export default Step2Component;
