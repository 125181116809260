import axios from "axios";

export const emailYourROIResults = async (data) => {
  return axios
    .post(
      `${process.env.REACT_APP_ROI_CALCULATOR_SERVICE_URL}/v1/emailResults`,
      {
        ...data,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_ROI_CALCULATOR_SERVICE_APIKEY,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const emailMarketing = async (data) => {
  return axios
    .post(
      `${process.env.REACT_APP_ROI_CALCULATOR_SERVICE_URL}/v1/emailMarketing`,
      {
        ...data,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_ROI_CALCULATOR_SERVICE_APIKEY,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
