import { Alert, Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ProgressBar from "./ProgressBar";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { emailMarketing, emailYourROIResults } from "./emailUtil";

const PAYBAK_NOT_ACHIEVED = "Not Achieved";

const ResultsComponent = (props) => {
  useEffect(() => {
    buildMatrix();
  }, []);

  const { goto, state, setResults } = props;

  let matrix = [];
  const buildMatrix = (month = 1, cumulative_dcf = 0.0) => {
    if (month === 1) matrix = []; // reset;
    const discountRate = (state.step2.inputDiscountRate ? parseInt(state.step2.inputDiscountRate) : 10) / 100;
    const DCF_CONSTANT_N4 = (1 + discountRate) ** (1 / 12) - 1;

    const member = month * (state.step2.inputNumberOfNewMembers ? parseInt(state.step2.inputNumberOfNewMembers) : 0);
    const client = month * (state.step2.inputNumberOfNewClients ? parseInt(state.step2.inputNumberOfNewClients) : 0);
    const profit = member * (state.step2.inputAvgNetProfitPerMember ? parseFloat(state.step2.inputAvgNetProfitPerMember) : 0.0) + client * (state.step2.inputAvgNetProfitPerClient ? parseFloat(state.step2.inputAvgNetProfitPerClient) : 0.0);
    const dcf = profit * (1 / (1 + DCF_CONSTANT_N4)) ** month;
    cumulative_dcf += dcf;

    let matrix_item = {
      month,
      member,
      client,
      profit,
      dcf,
      cumulativeDCF: cumulative_dcf,
    };

    matrix.push(matrix_item);

    const __systemCost__ = state.step2.inputSystemCost ? parseFloat(state.step2.inputSystemCost) : 0.0;
    if (month < 36 && (cumulative_dcf < __systemCost__ || (cumulative_dcf > __systemCost__ && month % 12 > 0))) {
      buildMatrix(++month, cumulative_dcf);
    } else {
      let payback = PAYBAK_NOT_ACHIEVED;
      let totalProfit = matrix.reduce((a, data) => {
        if (data.cumulativeDCF > __systemCost__ && payback === PAYBAK_NOT_ACHIEVED) payback = `${data.month}${data.month > 1 ? "mos." : "mo."}`;
        return a + data.dcf;
      }, 0.0);
      let npv = `$${parseFloat((totalProfit - __systemCost__).toFixed(2)).toLocaleString()}`;
      const dotIndex = npv.indexOf(".");
      if (dotIndex === -1) npv += ".00";
      else if (npv.substring(dotIndex).length < 3) npv += "0";
      const roi = `${Math.round(((totalProfit - __systemCost__) / __systemCost__) * 100).toLocaleString()}%`;
      setResults({
        profit: npv,
        paybackPeriod: payback,
        roi,
      });
    }
  };

  const emailResults = () => {
    if (state.results.paybackPeriod === PAYBAK_NOT_ACHIEVED) {
      toast.error("Please adjust input numbers in the previous step to reach profitability within a 36-month period.");
    } else {
      emailYourROIResults({
        email: state.step1.salesRepEmail,
        profit: state.results.profit,
        paybackPeriod: state.results.paybackPeriod,
        roi: state.results.roi,
      });

      emailMarketing({
        ...state.step1,
        ...state.step2,
        inputDiscountRate: `${!state.step2.inputDiscountRate || isNaN(state.step2.inputDiscountRate) ? "10" : parseInt(state.step2.inputDiscountRate).toFixed(0)}%`,
        inputSystemCost: `$${isNaN(state.step2.inputSystemCost) ? "0.00" : parseFloat(state.step2.inputSystemCost).toFixed(2)}`,
        inputAvgNetProfitPerMember: `$${isNaN(state.step2.inputAvgNetProfitPerMember) ? "0.00" : parseFloat(state.step2.inputAvgNetProfitPerMember).toFixed(2)}`,
        inputAvgNetProfitPerClient: `$${isNaN(state.step2.inputAvgNetProfitPerClient) ? "0.00" : parseFloat(state.step2.inputAvgNetProfitPerClient).toFixed(2)}`,
        ...state.results,
      });

      toast.success(`Your ROI Results have been sent to ${state.step1.salesRepEmail}`);
    }
  };

  return (
    <>
      <p>Based from your given inputs, here are your results. Please remember that this is an estimate with several assumptions. You either made these assumptions with your client or you made it for your client. If making the assumptions for your client be prepared to explain and justify those assumptions with them. You can always adjust the assumptions and recalculate the ROI.</p>

      <Container id="step3">
        <Row>
          <Col lg={5}>
            <Form.Label htmlFor="resultsProfit">Profit</Form.Label>
            <OverlayTrigger
              key="resultsProfitTooltip"
              placement="right"
              delay={{ hide: 3000 }}
              overlay={
                <Tooltip id={`results-profit-tooltip`}>
                  <strong>Profit</strong>
                  <br />
                  This is the estimated earnings at
                  <br />
                  the end of the year in which the
                  <br />
                  investment is paid back.
                </Tooltip>
              }
            >
              <span className="customer-tooltip">?</span>
            </OverlayTrigger>
            <Form.Control type="text" id="resultsProfit" readOnly value={state.results.profit} />
          </Col>
          <Col lg={3}>
            <Form.Label htmlFor="resultsPaybackPeriod">Payback Period</Form.Label>
            <OverlayTrigger
              key="resultsPaybackPeriodTooltip"
              placement="right"
              delay={{ hide: 3000 }}
              overlay={
                <Tooltip id={`results-payback-period-tooltip`}>
                  <strong>Payback Period</strong>
                  <br />
                  This is the number of months it
                  <br />
                  takes to break even from the investment.
                </Tooltip>
              }
            >
              <span className="customer-tooltip">?</span>
            </OverlayTrigger>
            <Form.Control type="text" id="resultsPaybackPeriod" value={state.results.paybackPeriod === PAYBAK_NOT_ACHIEVED ? "" : state.results.paybackPeriod} readOnly />
          </Col>
          <Col lg={4}>
            <Form.Label htmlFor="resultsROI">ROI</Form.Label>
            <OverlayTrigger
              key="resultsROITooltip"
              placement="right"
              delay={{ hide: 3000 }}
              overlay={
                <Tooltip id={`results-roi-tooltip`}>
                  <strong>ROI</strong>
                  <br />
                  This is the profit divided by the
                  <br />
                  initial investment.
                </Tooltip>
              }
            >
              <span className="customer-tooltip">?</span>
            </OverlayTrigger>
            <Form.Control type="text" id="resultsROI" value={state.results.roi} readOnly />
          </Col>
        </Row>
        {state.results.paybackPeriod === PAYBAK_NOT_ACHIEVED && (
          <Row>
            <Col lg={12}>
              <Alert key="danger" variant="danger">
                <Alert.Heading>{PAYBAK_NOT_ACHIEVED}</Alert.Heading>
                Please adjust input numbers in the previous step to reach profitability within a
                <br />
                36-month time period.
              </Alert>
            </Col>
          </Row>
        )}
        <Row className="next-button">
          <Col lg={12}>
            <div></div>
          </Col>
          <Col lg={4} md={4} sm={4} xs={12}>
            <Button
              variant="secondary"
              onClick={() => {
                goto(1, true);
              }}
            >
              Start Over
            </Button>
          </Col>
          <Col lg={4} md={4} sm={4} xs={12}>
            <Button
              variant="secondary"
              onClick={() => {
                goto(2);
              }}
            >
              Back
            </Button>
          </Col>
          <Col lg={4} md={4} sm={4} xs={12}>
            <Button
              className={state.results.paybackPeriod === PAYBAK_NOT_ACHIEVED ? "disabled" : ""}
              onClick={() => {
                emailResults();
              }}
            >
              Email Results
            </Button>
          </Col>
        </Row>
        <ProgressBar activeStep={3} />
      </Container>
    </>
  );
};

export default ResultsComponent;
