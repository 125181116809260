import logo from "../assets/img/default/lifefitness-logo.png";
import logodark from "../assets/img/dark/lifefitness-logo.png";
import imgStep1 from "../assets/img/device/step-1.png";
import fade from "../assets/img/default/device/fade.png";
import fadeDark from "../assets/img/dark/device/fade.png";
import imgStep2 from "../assets/img/device/step-2.png";
import imgStep3 from "../assets/img/device/step-3.png";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Container, Col, Row } from "react-bootstrap";

const HeaderComponent = (props) => {
  const [isDark, setIsDark] = useState(false);
  useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)",
    },
    undefined,
    (isSystemDark) => setIsDark(isSystemDark)
  );

  const { children, results } = props;
  return (
    <div className="main form">
      <Container>
        <Row>
          <Col id="lf-logo" xs={12} sm={12} md={12} lg={12}>
            {/* {isDark && <img src={logodark} className="lf-logo" alt="logo" />}
            {!isDark && <img src={logo} className="lf-logo" alt="logo" />} */}
            {results && (
              <>
                <div id="deviceHeaderBanner">
                  <img src={fade} id="imgFadeDefault" style={{ width: "100%", height: "75vw" }} />
                  <img src={fadeDark} id="imgFadeDark" style={{ width: "100%", height: "75vw" }} />
                </div>
                <br />
                <p className="your-connect-kit results">Your Connect Kit</p>
                <p className="lf-header results">YOUR ROI RESULTS</p>
                {children}
              </>
            )}
            {!results && (
              <>
                <div id="deviceHeaderBanner">
                  <img src={fade} id="imgFadeDefault" style={{ width: "100%", height: "75vw" }} />
                  <img src={fadeDark} id="imgFadeDark" style={{ width: "100%", height: "75vw" }} />
                </div>
                <p className="your-connect-kit">Your Connect Kit</p>
                <p className="lf-header">ROI Calculator</p>
                {children}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeaderComponent;
