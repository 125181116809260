import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./assets/css/default/index.css";
import RootContainer from "./containers/RootContainer";

function App() {
  return <RootContainer />;
}

export default App;
