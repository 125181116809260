import { useState } from "react";
import HeaderComponent from "../components/HeaderComponent";
import Step1Component from "../components/common/Step1Component";
import Step2Component from "../components/common/Step2Component";
import ResultsComponent from "../components/common/ResultsComponent";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const STEP1_INITIAL_STATE = {
  customerFirstName: "",
  customerLastName: "",
  salesRepEmail: "",
  customerFacilityName: "",
  customerSegment: "",
  customerZipCode: "",
  customerCurrentCustomer: "",
  invalid: true,
};

const STEP2_INITIAL_STATE = {
  inputSystemCost: "",
  inputDiscountRate: "",
  inputAvgNetProfitPerMember: "",
  inputNumberOfNewMembers: "",
  inputAvgNetProfitPerClient: "",
  inputNumberOfNewClients: "",
  invalid: true,
};

const STEP2_PAIRS = {
  inputAvgNetProfitPerMember: "inputNumberOfNewMembers",
  inputNumberOfNewMembers: "inputAvgNetProfitPerMember",
  inputAvgNetProfitPerClient: "inputNumberOfNewClients",
  inputNumberOfNewClients: "inputAvgNetProfitPerClient",
};

const RESULTS_INITIAL_STATE = {
  profit: 0.0,
  paybackPeriod: "",
  roi: "",
};

const RootContainer = () => {
  const [siteState, setSiteState] = useState({
    page: 1,
    startOver: true,
    step1: STEP1_INITIAL_STATE,
    step2: STEP2_INITIAL_STATE,
    results: RESULTS_INITIAL_STATE,
  });

  const goToStep = (pageNumber, startOver = false) => {
    if (startOver === true) {
      setSiteState({
        page: 1,
        startOver: true,
        step1: STEP1_INITIAL_STATE,
        step2: STEP2_INITIAL_STATE,
        results: RESULTS_INITIAL_STATE,
      });
    } else {
      setSiteState({
        ...siteState,
        page: parseInt(pageNumber),
        startOver,
      });
    }
  };

  const validate = (e, stepNumber, fieldName) => {
    if (stepNumber === "step1") return validateStep1(e, fieldName);
    else return validateStep2(e, fieldName);
  };

  const validateStep1 = (e, fieldName) => {
    return !Object.keys(siteState.step1)
      .filter((k) => k !== "invalid")
      .every((k) => {
        if (k === fieldName) return e.target.value.trim() !== "";
        else return siteState.step1[k].trim() !== "";
      });
  };

  const validateStep2 = (e, fieldName) => {
    return (
      Object.keys(STEP2_PAIRS).every((k) => {
        if (k === fieldName) return e.target.value.trim() === "";
        else return siteState.step2[k].trim() === "";
      }) ||
      !Object.keys(siteState.step2)
        .filter((k) => k !== "invalid" && k !== "inputDiscountRate")
        .every((k) => {
          if (k === fieldName) return validateStep2Value(e, fieldName, e.target.value, true);
          else return validateStep2Value(e, k, siteState.step2[k], false, STEP2_PAIRS[k] === fieldName);
        })
    );
  };

  const validateStep2Value = (e, name, value, thisFieldOnChange = false, partnerFieldOnChange = false) => {
    const getPartnerValue = (partnerFieldName) => {
      if (partnerFieldOnChange) return e.target.value;
      else return siteState.step2[partnerFieldName];
    };

    let valid = false;
    let partnerFieldValue = null;
    switch (name) {
      case "inputSystemCost":
        valid = validateFloat(value);
        break;
      case "inputAvgNetProfitPerMember":
        partnerFieldValue = getPartnerValue("inputNumberOfNewMembers");
        valid = (validateFloat(value) && validateInt(partnerFieldValue)) || (value.trim() === "" && partnerFieldValue.trim() === "");
        break;
      case "inputNumberOfNewMembers":
        partnerFieldValue = getPartnerValue("inputAvgNetProfitPerMember");
        valid = (validateFloat(partnerFieldValue) && validateInt(value)) || (value.trim() === "" && partnerFieldValue.trim() === "");
        break;
      case "inputAvgNetProfitPerClient":
        partnerFieldValue = getPartnerValue("inputNumberOfNewClients");
        valid = (validateFloat(value) && validateInt(partnerFieldValue)) || (value.trim() === "" && partnerFieldValue.trim() === "");
        break;
      case "inputNumberOfNewClients":
        partnerFieldValue = getPartnerValue("inputAvgNetProfitPerClient");
        valid = (validateFloat(partnerFieldValue) && validateInt(value)) || (value.trim() === "" && partnerFieldValue.trim() === "");
        break;
      default:
        break;
    }

    return valid;
  };

  const validateFloat = (value) => {
    return value !== "" && !isNaN(value) && parseFloat(value) > 0.0;
  };

  const validateInt = (value, min = -1, max = 100) => {
    return value !== "" && !isNaN(value) && parseInt(value) >= min && parseInt(value) <= max;
  };

  const setFieldValue = (e, stepNumber, fieldName) => {
    setSiteState({
      ...siteState,
      [stepNumber]: {
        ...siteState[stepNumber],
        [fieldName]: e.target.value,
        invalid: validate(e, stepNumber, fieldName),
      },
    });
  };

  const setResults = (results) => {
    setSiteState({
      ...siteState,
      results,
    });
  };

  return (
    <div className={`App page-${siteState.page}`}>
      <ToastContainer position="top-center" autoClose={8000} closeOnClick={false} hideProgressBar pauseOnHover={false} className="custom-toast-container" toastClassName="custom-toast" bodyClassName="custom-toast-body" />
      <div className="step">
        <HeaderComponent results={siteState.page === 3}>
          {siteState.page === 1 && <Step1Component goto={goToStep} state={siteState} startOver={siteState.startOver} setValue={setFieldValue} />}
          {siteState.page === 2 && <Step2Component goto={goToStep} state={siteState} setValue={setFieldValue} />}
          {siteState.page === 3 && <ResultsComponent goto={goToStep} state={siteState} setResults={setResults} />}
        </HeaderComponent>
      </div>
    </div>
  );
};

export default RootContainer;
